import React from "react";
import LogoItem from "../partials/LogoItem";
import MenuItem from "../partials/MenuItem";
import Container from "../partials/Container";

const menu = [
  { name: "Home", url: "/" },
  { name: "Mein Angebot", url: "/services" },
  { name: "Über mich", url: "/about" },
];

function Header() {
  return (
    <div
      className="uk-navbar-container"
      data-uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky uk-box-shadow-small; cls-inactive: uk-navbar-transparent; top: 80"
    >
      <Container>
        <nav className="uk-padding-small uk-padding-remove-horizontal" data-uk-navbar="">
          <div className="uk-navbar-left">
            <div data-uk-grid="">
              <LogoItem />
              <div>
                <h2 className="uk-margin-remove">Christina Behringer</h2>
                <div>trainiert und coacht</div>
              </div>
            </div>
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav uk-visible@l">
              {menu.map((item, index) => (
                <MenuItem key={index} url={item.url} name={item.name} />
              ))}
            </ul>
            <button className="uk-navbar-toggle uk-button uk-button-link uk-hidden@l" data-uk-toggle="#mobile">
              <div data-uk-navbar-toggle-icon="ratio: 1.5" />
            </button>
          </div>
        </nav>
      </Container>
      <div id="mobile" data-uk-offcanvas="overlay:true; mode: push; flip: true" className="uk-offcanvas">
        <div className="uk-offcanvas-bar uk-flex uk-flex-column uk-flex-middle uk-flex-center">
          <button className="uk-offcanvas-close uk-close uk-icon uk-padding" type="button" data-uk-close="ratio:2" />
          <ul className="uk-nav uk-nav-default">
            {menu.map((item, index) => (
              <MenuItem key={index} url={item.url} name={item.name} uk-close />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
