import React from "react";
import Section from "../partials/Section";
import Container from "../partials/Container";

class Welcome extends React.Component {
  render() {
    return (
      <Section scrollspy="target: [data-uk-scrollspy-class]; delay: 400;" cls="uk-section">
        <Container>
          <div className="uk-flex uk-flex-middle" data-uk-grid="">
            <div className="uk-width-xlarge@l" data-uk-scrollspy-class="uk-animation-slide-left-medium">
              <h2 className="uk-text-center">Herzlich Willkommen!</h2>
              <p>
                Als pragmatische Autodidaktin sehe ich stets die Lösung anstatt des Problems. Gemeinsam der Herausforderung auf den Grund gehen, Mut zur
                Veränderung hervorholen, das Positive erfassen und festhalten, den Willen stärken und letztlich <b>vom Reden ins Tun kommen</b> - das treibt
                mich an, mein Bestes für Sie und/oder Ihre Gruppe zu geben und erfolgreiche Ergebnisse zu erzielen. <b>Geht nicht, gibt's nicht.</b>
              </p>
              <p>
                So sage ich heute aus eigener Erfahrung, das Wichtigste im Leben ist, den Mut zu haben, eine bewusste Entscheidung zu treffen, sie umzusetzen
                und mit den Konsequenzen umzugehen. Nur Sie haben die Macht über den weiteren Weg Ihres Lebens zu entscheiden. Wann möchten Sie der Fluss sein
                und wann wie der Stein an seinem Platz bleiben? Darüber entscheiden nur Sie.
                <br />
                Ich unterstütze Sie dabei, Ihre individuelle Wahl zu treffen: Fluss oder Stein?
              </p>
              <h6 className="uk-text-right">
                <span>Christina Behringer</span>
              </h6>
            </div>
            <div className="uk-width-expand@l">
              {/*<div*/}
              {/*  className="uk-child-width-1-2 uk-grid-match uk-grid"*/}
              {/*  data-uk-grid="true"*/}
              {/*>*/}
              {/*<div className="uk-margin-large-top">*/}
              {/*  <div*/}
              {/*    className="uk-panel"*/}
              {/*    data-uk-scrollspy-class="uk-animation-slide-left-medium"*/}
              {/*  >*/}
              {/*    <div className="uk-overflow-hidden">*/}
              {/*      <div className="uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">*/}
              {/*        <img*/}
              {/*          alt="Stones"*/}
              {/*          data-uk-img="true"*/}
              {/*          src="https://images.unsplash.com/photo-1533162507191-d90c625b2640?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80"*/}
              {/*        />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="uk-margin-xlarge-top">*/}
              {/*  <div*/}
              {/*    className="uk-panel"*/}
              {/*    data-uk-scrollspy-class="uk-animation-slide-left-medium"*/}
              {/*  >*/}
              <div className="uk-overflow-hidden">
                <div className="uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
                  <img alt="Unsere Motivation" data-uk-img="" src="/assets/meine_motivation.png" />
                </div>
              </div>
            </div>
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
          </div>
        </Container>
      </Section>
    );
  }
}

export default Welcome;
