import React from "react";
import { HashLink as Link } from "react-router-hash-link";

function ServiceItem({ alt, description, h4, overlay = "default", src }) {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -110;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div>
      <div className="uk-inline-clip uk-transition-toggle uk-overflow-hidden uk-width-1-1 uk-flex uk-flex-center uk-flex-middle">
        <img
          className="uk-transition-scale-up uk-transition-opaque uk-background-cover uk-height-medium uk-width-1-1 cb-object-cover"
          width=""
          data-uk-img=""
          src={src}
          alt={alt}
        />
        <div
          className={
            "uk-position-cover uk-position-large uk-overlay uk-flex uk-flex-column uk-flex-center uk-overlay-" + overlay + " uk-flex-middle uk-text-center"
          }
        >
          <h4 className="uk-margin-remove tsf-text-shadow-small">{h4}</h4>
          <div className="tsf-text-shadow-small">{description}</div>
          <Link smooth to={"/services#" + h4} scroll={(el) => scrollWithOffset(el)}>
            los gehts!
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ServiceItem;
