import React from "react";
import { Link } from "react-router-dom";
import Section from "../partials/Section";
import Container from "../partials/Container";

class About extends React.Component {
  render() {
    return (
      <Section scrollspy="target: [data-uk-scrollspy-class]; delay: 400;">
        <Container>
          <div data-uk-grid="">
            <div className="uk-width-auto@s">
              <div className="uk-panel" data-uk-scrollspy-class="uk-animation-slide-left-medium">
                <div className="uk-overflow-hidden uk-flex uk-flex-center">
                  <div className="uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
                    <img alt="Unser Team" data-uk-img="true" src="assets/profile.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-expand@s" data-uk-scrollspy-class="uk-animation-slide-left-medium">
              <p>
                Seit 1990 bin ich im Umfeld des Coachings aktiv - zunächst als Verantwortliche in verschiedenen Bereichen und Abteilungen bei Hennes &amp;
                Mauritz und seit 2014 als selbstständige Trainerin.
              </p>
              <p>
                Meine langjährigen Berufserfahrungen haben mir gezeigt, was bei einem Coaching tatsächlich funktioniert und was nicht, worauf es ankommt und was
                unnötiger Ballast ist. Wie erreiche ich wirklich etwas bei meinen Teilnehmern? Was wirkt nachhaltig?
              </p>
              <Link to="/about" className="uk-button uk-button-primary uk-position-relative">
                Erfahren Sie mehr über mich
              </Link>
            </div>
          </div>
        </Container>
      </Section>
    );
  }
}

export default About;
