import React from "react";

class SlideShowItem extends React.Component {
  render() {
    const { sizes, srcset, src, alt, h1, h2, href, name } = this.props;
    return (
      <li>
        <div className="uk-position-cover uk-animation-kenburns uk-animation-reverse uk-transform-origin-center-center">
          <img alt={alt} data-uk-cover="" data-uk-img="target: !.uk-slideshow-items" data-src={src} data-sizes={sizes} data-srcset={srcset} />
        </div>
        <div
          className="uk-position-large uk-position-cover uk-overlay uk-flex uk-flex-column uk-flex-center uk-flex-middle"
          data-uk-scrollspy="target: > *; cls: uk-animation-slide-bottom; delay: 600;"
        >
          <hr className="uk-divider-small" />
          <h2 className="uk-text-lead uk-margin-remove-adjacent">{h2}</h2>
          <h1 className="uk-heading-primary">{h1.toUpperCase()}</h1>
          <p>
            <a href={href} className="uk-button uk-button-default" data-uk-scroll="offset: 115">
              {name}
            </a>
          </p>
        </div>
      </li>
    );
  }
}

export default SlideShowItem;
