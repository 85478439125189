import React, { Component } from "react";
import { Route, Routes } from "react-router";
import Home from "./pages/Home";
import Service from "./pages/Service";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import FourOhFour from "./pages/FourOhFour";

import "./App.scss";
import Layout from "./components/Layout";

class App extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/services" element={<Service />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="*" element={<FourOhFour />} />
        </Route>
        <Route path="*" element={<FourOhFour />} />
      </Routes>
    );
  }
}

export default App;
