import React from "react";
import Container from "../partials/Container";
import Section from "../partials/Section";

class Slogan extends React.Component {
  render() {
    return (
      <Section
        scrollspy="target: [data-uk-scrollspy-class]; delay: 400;"
        cls="uk-background-fixed uk-background-center-center uk-light"
        customStyle={{ backgroundImage: "url(assets/bg1.jpg)" }}
      >
        <Container>
          <h2 className="uk-heading-primary uk-text-center uk-text-uppercase">"Vom Reden ins Tun kommen"</h2>
        </Container>
      </Section>
    );
  }
}

export default Slogan;
