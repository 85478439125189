import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Outlet, useLocation } from "react-router";
import Navbar from "./Navbar";
import Section from "../partials/Section";
import Container from "../partials/Container";

function Layout() {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <>
      <div className="uk-width-1-1" data-uk-header="">
        <Navbar />
      </div>
      <Outlet />
      <Section cls="uk-padding-remove uk-section-primary">
        <Container>
          <div className="uk-grid-margin uk-padding-small" data-uk-grid="">
            <div className="uk-grid-item-match uk-flex-middle uk-width-expand@s">
              <div className="uk-panel">
                <div className="uk-text-meta uk-margin uk-text-left@s uk-text-center">© {new Date().getFullYear()} Christina Behringer</div>
              </div>
            </div>
            <div className="uk-grid-item-match uk-flex-middle uk-width-medium@s">
              <div className="uk-panel">
                <div className="uk-margin uk-text-center@s uk-text-center">
                  <img width="80" alt="Logo" data-src="/assets/logo-inverse.svg" data-uk-img="" />
                </div>
              </div>
            </div>
            <div className="uk-grid-item-match uk-flex-middle uk-width-expand@s">
              <div className="uk-panel">
                <div className="uk-text-right@s uk-text-center">
                  <ul className="uk-subnav uk-margin-remove-bottom uk-flex-right@s uk-flex-center" data-uk-margin="true">
                    <li>
                      <Link to="/privacy">Datenschutz</Link>
                    </li>
                    <li>
                      <Link to="/terms">Impressum</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
}

export default Layout;
