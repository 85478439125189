import React from "react";
import { Link } from "react-router-dom";

class LogoItem extends React.Component {
  render() {
    return (
      <Link className="uk-logo uk-visible@s" to="/">
        <img width="80" src="/assets/logo.svg" alt="Tanzschule Fischer" />
        <img width="80" src="/assets/logo-inverse.svg" alt="Tanzschule Fischer" className="uk-logo-inverse" />
      </Link>
    );
  }
}

export default LogoItem;
