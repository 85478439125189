import React from "react";
import Section from "../partials/Section";
import Container from "../partials/Container";

const cards = [
  {
    src: "/assets/vogelhaus.jpg",
    alt: "",
    h4: "Individuelle Bewerbertrainings",
    description:
      "Feststellung persönlicher Stärken und beruflicher Ziele. Erstellung von individuellen Bewerbungsunterlagen. Vorbereitung auf Vorstellungsgespräche und die ersten Tage im neuen Betrieb. Unterstützung in Fragen des Arbeitsrechts sowie der Zeugnissprache. Rechte und Pflichten als Arbeitnehmer.",
  },
  {
    src: "/assets/landschaft03.jpg",
    alt: "",
    h4: "Bewerbungstrainings für Firmen",
    description:
      "Feststellung firmeninterner Ansprüche an neue Mitarbeiter, Vorbereitung auf das Auswahlverfahren sowie das Vorstellungsgespräch.\n" +
      "Unterstützung in Fragen der Zeugnissprache. Die ersten Arbeitstage mit dem neuen Mitarbeiter. Rechte und Pflichten als Arbeitgeber.",
  },
  {
    src: "https://images.unsplash.com/photo-1474631245212-32dc3c8310c6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
    alt: "",
    h4: "Präsentationstechniken/ Moderationstechniken/ Projektmanagement",
    description:
      "Lernen vor einer Gruppe zu stehen und Inhalte nachhaltig zu erarbeiten. Tipps und Tricks für den Umgang mit unterschiedlichen Teilnehmern, Gruppendynamik und Techniken der Weitergabe. Teams erfolgreich zum Ziel führen, gemeinsame Projekte starten und beenden.",
  },
  {
    src: "/assets/kommunikationstraining.jpg",
    alt: "",
    h4: "Kommunikationstrainings",
    description:
      "Was ist Kommunikation. Hintergründe erfahren, Gesprächstechniken sowie den Umgang mit herausfordernden Gesprächspartnern erlernen.  Die Bedeutung des “ersten Eindrucks” erkennen. Körpersprache lesen. Unbewusste Körpersprache bewusst machen.",
  },
  {
    src: "https://images.unsplash.com/photo-1515531981002-91b2a11aa75d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
    alt: "",
    h4: "Zeitmanagement",
    description: "Lernen mit Stressfaktoren umzugehen. Ihre persönliche Einstellung zum Thema Zeit und Stress erkennen.",
  },
  {
    src: "/assets/landschaft01.jpg",
    alt: "",
    h4: "Einzelcoachings",
    description:
      "Sie sind Führungskraft und stoßen bei bestimmten Punkten an Ihre Grenzen? Sie befinden sich in einer persönlichen Krise? Sie möchten sich beruflich umorientieren? Hier greift mein Angebot des individuellen Coachings. In einem Orientierungsgespräch legen wir die für Sie passende Vorgehensweise fest.",
  },
  {
    src: "/assets/konfliktmanagement.jpg",
    alt: "",
    h4: "Konfliktmanagement",
    description:
      "Ob es im Team zu Spannungen kommt oder Sie sich in einem persönlichen Konflikt befinden-  gemeinsam stoßen wir auf den Kern des Konflikts. Mit Ansätzen aus der Mediation lernen Sie den Konflikt zu lösen, sowie einen Perspektivwechsel vorzunehmen - geschlossene Schranke oder freie Bahn?",
  },
  {
    src: "/assets/scrabble_verkaufstrainingt.jpg",
    alt: "",
    h4: "Verkaufstrainings",
    description:
      "Der Handel im Wandel der Zeit. Der Kunde im Mittelpunkt. Erkennen der eigenen Einstellung, Potenziale und Möglichkeiten. Kundenansprache, Zusatzverkäufe erzielen, Umgang mit herausfordernden Kundensituationen, Chancen bei Reklamation und Umtausch erkennen und nutzen.",
  },
  {
    src: "/assets/schriftstuecke1.jpg",
    alt: "",
    h4: "Erstellen persönlicher Schriftstücke",
    description:
      "Mit viel Einfühlungsvermögen möchte ich Sie beim Verfassen sehr persönlicher Texte unterstützen. Ob Liebesbrief, Kondolenzschreiben oder Korrespondenz mit offiziellen Adressaten (Firmen, Behörden etc.)- individuell gehe ich auf Ihre Persönlichkeit ein und werde stets das Ziel erreichen, dass Sie mit dem Inhalt identifiziert werden.",
  },
  {
    src: "/assets/aufraeumcoach.jpg",
    alt: "",
    h4: "Aufräumcoach",
    description:
      "Sie denken mindestens einmal in der Woche: „Hier müsste ich auch mal aufräumen!“?\n" +
      "Sie fangen an und verlieren sich in Kleinigkeiten und können sich nicht trennen, mit dem Gedanken, „Das war mal teuer oder das war doch mal schön.“?\n" +
      "Hier steige ich ein und unterstütze Sie aktiv dabei, wieder zu einem aufgeräumten, geordnetem und übersichtlichem Umfeld zu gelangen, wo Sie immer wissen, wo Dinge ihren Platz haben und nie mehr suchen müssen. Ein Umfeld in dem Sie nur noch von wirklich nützlichen und tatsächlich geliebten Dingen umgeben sind.",
  },
  {
    src: "/assets/scrabble_zufriedenheit.jpg",
    alt: "",
    h4: "Noch nicht das Tüpfelchen auf dem “i” gefunden?",
    description:
      "Meine Leidenschaft ist es, Ideen aufzunehmen und daraus Konzepte zu entwickeln, die genau auf Ihre Bedürfnisse angepasst sind. Ich weiß, dass ich auch für Sie und Ihre persönliche Situation eine Strategie entwickle, die Sie erfolgreich umsetzen werden!",
  },
];

function Service() {
  return (
    <>
      <div
        data-uk-scrollspy="target: [data-uk-scrollspy-class];"
        data-src="/assets/schriftstuecke.jpg"
        data-uk-img=""
        className="uk-background-norepeat uk-background-cover uk-background-center-center uk-flex uk-flex-middle"
        data-uk-height-viewport=""
      >
        <div className="uk-width-1-1 uk-text-center" data-uk-scrollspy-class="uk-animation-slide-bottom-medium">
          <h2>Mein Angebot für Sie</h2>
        </div>
      </div>
      <Section cls="uk-section-large" scrollspy="target: [data-uk-scrollspy-class];">
        <Container>
          {cards.map((item, index) => (
            <div key={index} className="uk-grid-margin" data-uk-grid="">
              {index % 2 !== 0 ? <div className="uk-width-expand@m" /> : ""}
              <div className="uk-width-5-6@m">
                <div className="uk-card uk-card-default uk-card-large uk-margin" id={item.h4} data-uk-scrollspy-class="uk-animation-slide-bottom-medium">
                  <div className="uk-child-width-expand uk-grid-collapse uk-grid-match" data-uk-grid="">
                    <div className="uk-width-1-2@s">
                      <div className="uk-card-media-left uk-cover-container">
                        <img className="uk-cover" alt={item.h4} data-uk-cover="" data-src={item.src} data-uk-img="" />
                        <img className="uk-invisible" alt={item.h4} data-src={item.src} data-uk-img="" />
                      </div>
                    </div>
                    <div>
                      <div className="uk-card-body uk-margin-remove-first-child">
                        <h3 className="uk-h3 uk-margin-top uk-margin-remove-bottom">{item.h4}</h3>
                        <div className="uk-margin-top">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {index % 2 === 0 ? <div className="uk-width-expand@m" /> : ""}
            </div>
          ))}
        </Container>
      </Section>
    </>
  );
}

export default Service;
