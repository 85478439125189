import React from "react";
import Section from "../partials/Section";
import Container from "../partials/Container";

function About() {
  return (
    <>
      <div
        data-uk-scrollspy="target: [data-uk-scrollspy-class];"
        data-src="https://images.unsplash.com/photo-1493219686142-5a8641badc78?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80"
        data-uk-img=""
        className="uk-background-norepeat uk-background-cover uk-background-center-center uk-flex uk-flex-middle"
        data-uk-height-viewport=""
      >
        <div className="uk-position-center">
          <div data-uk-scrollspy-class="uk-animation-slide-bottom-medium">
            <h2 style={{ transform: "rotateZ(-21deg)", transformOrigin: "50%" }}>Über mich</h2>
          </div>
        </div>
      </div>
      <Section cls="uk-section-default uk-section uk-section-large" scrollspy="target: [data-uk-scrollspy-class]; cls: uk-animation-fade;">
        <Container>
          <div className="uk-grid-large uk-grid-margin-large" data-uk-grid="">
            <div className="uk-width-expand@m">
              <img className="uk-visible@m uk-width-1-1" src="/assets/profile.jpg" alt="" />
              <h2 className="uk-margin-remove-top" data-uk-scrollspy-class="uk-animation-fade">
                Christina Behringer
              </h2>
            </div>
            <div className="uk-width-2-3@m">
              <div className="uk-text-meta uk-margin-small" data-uk-scrollspy-class="uk-animation-fade">
                seit 2014
              </div>
              <h3 className="uk-h2 uk-margin-small" data-uk-scrollspy-class="uk-animation-fade">
                Selbständigkeit als Trainerin und Coach
              </h3>
              <ul className="uk-list uk-margin-medium" data-uk-scrollspy-class="uk-animation-fade">
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>bfz, Berufsfortbildungszentrum, Kempten</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>TraIn, Transfer und Integration, Tapfheim</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <a href="https://www.metabasisgmbh.de" target="_blank" rel="noopener">
                      <div>Traineragentur Firma Metabasis GmbH, Heiningen</div>
                      <div>Im Auftrag der Firma Metabasis GmbH</div>
                    </a>
                  </div>
                  <ul className="uk-list" data-uk-scrollspy-class="uk-animation-fade">
                    <li>
                      <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                        <div className="uk-width-auto">
                          <span className="uk-icon" data-uk-icon="icon: check;" />
                        </div>
                        <div>
                          <div>für Apollo-Optik GmbH sowie für Pearle-Optik (Österreich)</div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                        <div className="uk-width-auto">
                          <span className="uk-icon" data-uk-icon="icon: check;" />
                        </div>
                        <div>
                          <div>für die Deutsche Telekom AG, Telekom Shop Vertriebsgesellschaft</div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                        <div className="uk-width-auto">
                          <span className="uk-icon" data-uk-icon="icon: check;" />
                        </div>
                        <div>
                          <div>für die BayWa AG</div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                        <div className="uk-width-auto">
                          <span className="uk-icon" data-uk-icon="icon: check;" />
                        </div>
                        <div>
                          <div>für den CityRing, Kirchheim Teck</div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                        <div className="uk-width-auto">
                          <span className="uk-icon" data-uk-icon="icon: check;" />
                        </div>
                        <div>
                          <div>für Monsoon Accessorize Limited</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Coaching eigener Kunden</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>BZE, Berufszentrum für den Einzelhandel, Springe</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <a href="https://www.poko.de" target="_blank" rel="noopener">
                        PoKo – Institut OHG, Münster „Institut für politische Kommunikation“, Seminare für Betriebsräte
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              <hr className="uk-margin-medium" data-uk-scrollspy-class="uk-animation-fade" />
              <div className="uk-text-meta uk-margin-small" data-uk-scrollspy-class="uk-animation-fade">
                1990- 2014
              </div>
              <h3 className="uk-h2 uk-margin-small" data-uk-scrollspy-class="uk-animation-fade">
                Mitarbeiterin bei Hennes & Mauritz
              </h3>
              <ul className="uk-list uk-margin-medium" data-uk-scrollspy-class="uk-animation-fade">
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Ausbildungsverantwortliche Deutschland</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Trainingsverantwortliche Deutschland</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Human Ressources im Süddeutschen Raum</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Unterstützung des Areamanagements</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Coaching von Filialleitungen</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Filialleitung in Berlin und Kempten</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Abteilungsleiterin in Berlin</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Kassenverantwortliche in Berlin</div>
                    </div>
                  </div>
                </li>
              </ul>
              <h3 className="uk-h2 uk-margin-small" data-uk-scrollspy-class="uk-animation-fade">
                Sekretärin und Importbearbeiterin, Weincompagny, Berlin
              </h3>
              <h3 className="uk-h2 uk-margin-small" data-uk-scrollspy-class="uk-animation-fade">
                Mitarbeiterin im Verkauf, Hennes & Mauritz, Berlin
              </h3>
              <h3 className="uk-h2 uk-margin-small" data-uk-scrollspy-class="uk-animation-fade">
                Ausbildung zur Restaurantfachfrau, Hotel Fürstenhof, Celle
              </h3>
              <hr className="uk-margin-medium" data-uk-scrollspy-class="uk-animation-fade" />
              <h3 className="uk-h2 uk-margin-small" data-uk-scrollspy-class="uk-animation-fade">
                Zusatzqualifikationen
              </h3>
              <ul className="uk-list uk-margin-medium" data-uk-scrollspy-class="uk-animation-fade">
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Ausbildereignungsschein (AdA)</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Ehrenamtliche Mitarbeit im Prüfungsausschuss Verkäufer/in, Kaufmann/-frau im Einzelhandel</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Teilnahme als Sachverständige des Bundes für die Fortbildungsverordnung zum/r geprüften Handelsassistenten/in im Einzelhandel</div>
                    </div>
                  </div>
                </li>
              </ul>
              <hr className="uk-margin-medium" data-uk-scrollspy-class="uk-animation-fade" />
              <h3 className="uk-h2 uk-margin-small" data-uk-scrollspy-class="uk-animation-fade">
                Meine Persönlichkeit
              </h3>
              <ul className="uk-list uk-margin-medium" data-uk-scrollspy-class="uk-animation-fade">
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Autodidaktin</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Praktikerin</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Teamplayer</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Empathisch</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Geduldig</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Pragmatisch</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Wortgewandt</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Humorvoll</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Engagiert</div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-top" data-uk-grid="">
                    <div className="uk-width-auto">
                      <span className="uk-icon" data-uk-icon="icon: check;" />
                    </div>
                    <div>
                      <div>Kritikfähig</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
}

export default About;
