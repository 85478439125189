import React from "react";
import { Link } from "react-router-dom";
import { useMatch, useResolvedPath } from "react-router";

function MenuItem({ name, url }) {
  let resolved = useResolvedPath(url);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <li className={match ? "uk-active" : ""}>
      <Link to={url}>{name.toUpperCase()}</Link>
    </li>
  );
}

export default MenuItem;
