import React from "react";
import SlideShowItem from "../partials/SlideShowItem";

class SlideShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          src: "https://images.unsplash.com/photo-1513542789411-b6a5d4f31634?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
          alt: "missing alt tag",
          h1: "Sie",
          h2: "Meine Mission sind",
          href: "#contact",
          name: "Kontaktieren Sie mich",
        },
        {
          src: "https://images.unsplash.com/photo-1440335680360-79703e7032f9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
          alt: "missing alt tag",
          h1: "Sie",
          h2: "Meine Mission sind",
          href: "#contact",
          name: "Kontaktieren Sie mich",
        },
        {
          src: "https://images.unsplash.com/photo-1493219686142-5a8641badc78?ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
          alt: "missing alt tag",
          h1: "Sie",
          h2: "Meine Mission sind",
          href: "#contact",
          name: "Kontaktieren Sie mich",
        },
      ],
    };
  }
  render() {
    return (
      <div data-uk-slideshow="animation: fade; autoplay: 0; autoplay-interval: 4000">
        <div className="uk-position-relative">
          <ul className="uk-slideshow-items" data-uk-height-viewport="offset-top: true; minHeight: 600;">
            {this.state.items.map((item, index) => (
              <SlideShowItem
                key={index}
                sizes={item.sizes}
                srcset={item.srcset}
                src={item.src}
                alt={item.alt}
                h1={item.h1}
                h2={item.h2}
                href={item.href}
                name={item.name}
              />
            ))}
          </ul>
          <button className="uk-position-center-left" data-uk-slideshow-item={"previous"} data-uk-icon="chevron-left" data-ratio="4" />
          <button className="uk-position-center-right" data-uk-slideshow-item="next" data-uk-icon="chevron-right" data-ratio="4" />
        </div>
      </div>
    );
  }
}

export default SlideShow;
