import React from "react";
import SlideShow from "../components/Slideshow";
import Welcome from "../components/Welcome";
import Services from "../components/Services";
import Slogan from "../components/Slogan";
import Contact from "../components/Contact";
import About from "../components/About";
import Section from "../partials/Section";
import Container from "../partials/Container";

function Home() {
  return (
    <>
      <SlideShow />
      <Section
        scrollspy="target: [data-uk-scrollspy-class]"
        cls="uk-background-fixed uk-background-center-center uk-light"
        customStyle={{ backgroundImage: "url(assets/bg4.jpg)" }}
      >
        <Container>
          <div className="uk-height-small" />
        </Container>
      </Section>
      <Welcome />
      <Slogan />
      <Services />
      <Contact />
      <About />
    </>
  );
}

export default Home;
