import React from "react";
import Section from "../partials/Section";
import Container from "../partials/Container";

const contact = {
  forename: "Christina",
  name: "Behringer",
  company: "Christina Behringer",
  street: "An der Sutt 13",
  postalcode: "87435",
  city: "Kempten",
  country: "Germany",
  mail: "christina.behringer@gmx.de",
  phone: "+49 0151 614 33 162",
  terms: "/terms",
};

function Privacy() {
  const { forename, name, company, street, postalcode, city, country, mail, terms } = contact;
  return (
    <>
      <Section />
      <Section>
        <Container>
          <h2>Datenschutzerklärung</h2>
          <p>
            Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) im
            Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie
            externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die
            verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
            Datenschutzgrundverordnung (DSGVO).
          </p>
          <h3>Verantwortlicher</h3>
          <p>
            {forename} {name} {company}
            <br />
            {street}
            <br />
            {postalcode} {city}
            <br />
            {country}
            <br />
            <br />
            {mail}
            <br />
            <a href={terms}>Link zum Impressum</a>
          </p>
          <h3>Arten der verarbeiteten Daten</h3>
          <p>
            - Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).
            <br />
            - Kontaktdaten (z.B., E-Mail, Telefonnummern).
            <br />
            - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).
            <br />
            - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).
            <br />- Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).
          </p>
          <h3>Kategorien betroffener Personen</h3>
          <p>Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“).</p>
          <h3>Zweck der Verarbeitung</h3>
          <p>
            - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.
            <br />
            - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
            <br />
            - Sicherheitsmaßnahmen.
            <br />- Reichweitenmessung/Marketing
          </p>
          <h3>Verwendete Begrifflichkeiten </h3>
          <p>
            „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene
            Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer
            Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen
            Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder
            sozialen Identität dieser natürlichen Person sind.
            <br />
            <br />
            „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
            personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.
            <br />
            <br />
            „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher
            Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert
            aufbewahrt werden und technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer
            identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
            <br />
            <br />
            „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet
            werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich
            Arbeitsleistung, wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel
            dieser natürlichen Person zu analysieren oder vorherzusagen.
            <br />
            <br />
            Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen
            über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
            <br />
            <br />
            „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des
            Verantwortlichen verarbeitet.
          </p>
          <h3>Maßgebliche Rechtsgrundlagen</h3>
          <p>
            Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich der
            Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird,
            Folgendes: <br />
            Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO;
            <br />
            Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen
            ist Art. 6 Abs. 1 lit. b DSGVO;
            <br />
            Die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;
            <br />
            Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten
            erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
            <br />
            Die Rechtsgrundlage für die erforderliche Verarbeitung zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde ist Art. 6 Abs. 1 lit. e DSGVO. <br />
            Die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. <br />
            Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie erhoben wurden, bestimmt sich nach den Vorgaben des Art 6 Abs. 4 DSGVO. <br />
            Die Verarbeitung von besonderen Kategorien von Daten (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich nach den Vorgaben des Art. 9 Abs. 2 DSGVO.
          </p>
          <h3>Sicherheitsmaßnahmen</h3>
          <p>
            Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art, des
            Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte
            und Freiheiten natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu
            gewährleisten.
            <br />
            <br />
            Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
            Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des
            Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung der Daten
            gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie
            Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
          </p>
          <h3>Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und Dritten</h3>
          <p>
            Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern, gemeinsam Verantwortlichen oder
            Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen
            Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserfüllung erforderlich ist), Nutzer
            eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von
            Beauftragten, Webhostern, etc.). <br />
            <br />
            Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe offenbaren, übermitteln oder ihnen sonst den Zugriff gewähren, erfolgt dies
            insbesondere zu administrativen Zwecken als berechtigtes Interesse und darüberhinausgehend auf einer den gesetzlichen Vorgaben entsprechenden
            Grundlage.
          </p>
          <h3>Übermittlungen in Drittländer</h3>
          <p>
            Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU), des Europäischen Wirtschaftsraums (EWR) oder der Schweizer
            Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an andere
            Personen oder Unternehmen geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung,
            aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich ausdrücklicher Einwilligung
            oder vertraglich erforderlicher Übermittlung, verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, zu
            denen die unter dem "Privacy-Shield" zertifizierten US-Verarbeiter gehören oder auf Grundlage besonderer Garantien, wie z.B. vertraglicher
            Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, dem Vorliegen von Zertifizierungen oder verbindlichen internen
            Datenschutzvorschriften verarbeiten (Art. 44 bis 49 DSGVO,{" "}
            <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_de" target="blank">
              Informationsseite der EU-Kommission
            </a>
            ).
          </p>
          <h3>Rechte der betroffenen Personen</h3>
          <p>
            Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf
            weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
            <br />
            <br />
            Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie
            betreffenden unrichtigen Daten zu verlangen.
            <br />
            <br />
            Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach
            Maßgabe der gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.
            <br />
            <br />
            Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe der gesetzlichen Vorgaben zu
            erhalten und deren Übermittlung an andere Verantwortliche zu fordern. <br />
            <br />
            Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
          </p>
          <h3>Widerrufsrecht</h3>
          <p>Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die Zukunft zu widerrufen.</p>
          <h3>Widerspruchsrecht</h3>
          <p>
            <strong>
              Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe der gesetzlichen Vorgaben jederzeit widersprechen. Der Widerspruch
              kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.
            </strong>
          </p>
          <h3>Cookies und Widerspruchsrecht bei Direktwerbung</h3>
          <p>
            Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der Cookies können unterschiedliche
            Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während
            oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“,
            werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser schließt. In einem solchen Cookie
            kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Status gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies
            bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese
            nach mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung oder
            Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das
            Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von „First-Party Cookies“).
            <br />
            <br />
            Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer Datenschutzerklärung auf.
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung in den Einsatz von Cookies bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die Rechtsgrundlage
            dieser Verarbeitung Art. 6 Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen Cookies der Nutzer entsprechend den nachfolgenden
            Erläuterungen im Rahmen dieser Datenschutzerklärung auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
            wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) oder sofern der Einsatz von Cookies zur Erbringung
            unserer vertragsbezogenen Leistungen erforderlich ist, gem. Art. 6 Abs. 1 lit. b. DSGVO, bzw. sofern der Einsatz von Cookies für die Wahrnehmung
            einer Aufgabe, die im öffentlichen Interesse liegt erforderlich ist oder in Ausübung öffentlicher Gewalt erfolgt, gem. Art. 6 Abs. 1 lit. e. DSGVO,
            verarbeitet.
            <br />
            <br />
            Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in den
            Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen des Browsers gelöscht werden. Der
            Ausschluss von Cookies kann zu Funktionseinschränkungen dieses Onlineangebotes führen.
            <br />
            <br />
            Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem
            im Fall des Trackings, über die US-amerikanische Seite <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> oder die
            EU-Seite <a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a> erklärt werden. Des Weiteren kann die Speicherung von
            Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle
            Funktionen dieses Onlineangebotes genutzt werden können.
          </p>
          <h3>Löschung von Daten</h3>
          <p>
            Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im
            Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung
            nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. <br />
            <br />
            Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung
            eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
            steuerrechtlichen Gründen aufbewahrt werden müssen.
          </p>
          <h3>Änderungen und Aktualisierungen der Datenschutzerklärung</h3>
          <p>
            Wir bitten Sie sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die
            Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine
            Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.
          </p>
          <h3>Agenturdienstleistungen</h3>
          <p>
            Wir verarbeiten die Daten unserer Kunden im Rahmen unserer vertraglichen Leistungen zu denen konzeptionelle und strategische Beratung,
            Kampagnenplanung, Software- und Designentwicklung/-beratung oder Pflege, Umsetzung von Kampagnen und Prozessen/ Handling, Serveradministration,
            Datenanalyse/ Beratungsleistungen und Schulungsleistungen gehören.
            <br />
            <br />
            Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten, wie Namen oder Adressen), Kontaktdaten (z.B., E-Mail, Telefonnummern), Inhaltsdaten
            (z.B., Texteingaben, Fotografien, Videos), Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit), Zahlungsdaten (z.B., Bankverbindung,
            Zahlungshistorie), Nutzungs- und Metadaten (z.B. im Rahmen der Auswertung und Erfolgsmessung von Marketingmaßnahmen). Besondere Kategorien
            personenbezogener Daten verarbeiten wir grundsätzlich nicht, außer wenn diese Bestandteile einer beauftragten Verarbeitung sind. Zu den Betroffenen
            gehören unsere Kunden, Interessenten sowie deren Kunden, Nutzer, Websitebesucher oder Mitarbeiter sowie Dritte. Der Zweck der Verarbeitung besteht
            in der Erbringung von Vertragsleistungen, Abrechnung und unserem Kundenservice. Die Rechtsgrundlagen der Verarbeitung ergeben sich aus Art. 6 Abs. 1
            lit. b DSGVO (vertragliche Leistungen), Art. 6 Abs. 1 lit. f DSGVO (Analyse, Statistik, Optimierung, Sicherheitsmaßnahmen). Wir verarbeiten Daten,
            die zur Begründung und Erfüllung der vertraglichen Leistungen erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe hin. Eine
            Offenlegung an Externe erfolgt nur, wenn sie im Rahmen eines Auftrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags
            überlassenen Daten handeln wir entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben einer Auftragsverarbeitung gem. Art. 28
            DSGVO und verarbeiten die Daten zu keinen anderen, als den auftragsgemäßen Zwecken.
            <br />
            <br />
            Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten. die Erforderlichkeit der Aufbewahrung der Daten wird
            alle drei Jahre überprüft; im Fall der gesetzlichen Archivierungspflichten erfolgt die Löschung nach deren Ablauf (6 J, gem. § 257 Abs. 1 HGB, 10 J,
            gem. § 147 Abs. 1 AO). Im Fall von Daten, die uns gegenüber im Rahmen eines Auftrags durch den Auftraggeber offengelegt wurden, löschen wir die
            Daten entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
          </p>
          <h3>Therapeutische Leistungen und Coaching</h3>
          <p>
            Wir verarbeiten die Daten unserer Klienten und Interessenten und anderer Auftraggeber oder Vertragspartner (einheitlich bezeichnet als „Klienten“)
            entsprechend Art. 6 Abs. 1 lit. b) DSGVO, um ihnen gegenüber unsere vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die hierbei
            verarbeiteten Daten, die Art, der Umfang und der Zweck und die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem zugrundeliegenden
            Vertragsverhältnis. Zu den verarbeiteten Daten gehören grundsätzlich Bestands- und Stammdaten der Klienten (z.B., Name, Adresse, etc.), als auch die
            Kontaktdaten (z.B., E-Mailadresse, Telefon, etc.), die Vertragsdaten (z.B., in Anspruch genommene Leistungen, Honorare, Namen von Kontaktpersonen,
            etc.) und Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie, etc.).
            <br />
            <br />
            Im Rahmen unserer Leistungen, können wir ferner besondere Kategorien von Daten gem. Art. 9 Abs. 1 DSGVO, insbesondere Angaben zur Gesundheit der
            Klienten, ggf. mit Bezug zu deren Sexualleben oder der sexuellen Orientierung, ethnischer Herkunft oder religiösen oder weltanschaulichen
            Überzeugungen, verarbeiten. Hierzu holen wir, sofern erforderlich, gem. Art. 6 Abs. 1 lit. a., Art. 7, Art. 9 Abs. 2 lit. a. DSGVO eine
            ausdrückliche Einwilligung der Klienten ein und verarbeiten die besonderen Kategorien von Daten ansonsten zu Zwecken der Gesundheitsvorsorge auf
            Grundlage des Art. 9 Abs. 2 lit h. DSGVO, § 22 Abs. 1 Nr. 1 b. BDSG.
            <br />
            <br />
            Sofern für die Vertragserfüllung oder gesetzlich erforderlich, offenbaren oder übermitteln wir die Daten der Klienten im Rahmen der Kommunikation
            mit anderen Fachkräften, an der Vertragserfüllung erforderlicherweise oder typischerweise beteiligten Dritten, wie z.B. Abrechnungsstellen oder
            vergleichbare Dienstleister, sofern dies der Erbringung unserer Leistungen gem. Art. 6 Abs. 1 lit b. DSGVO dient, gesetzlich gem. Art. 6 Abs. 1 lit
            c. DSGVO vorgeschrieben ist, unseren Interessen oder denen der Klienten an einer effizienten und kostengünstigen Gesundheitsversorgung als
            berechtigtes Interesse gem. Art. 6 Abs. 1 lit f. DSGVO dient oder gem. Art. 6 Abs. 1 lit d. DSGVO notwendig ist. um lebenswichtige Interessen der
            Klienten oder einer anderen natürlichen Person zu schützen oder im Rahmen einer Einwilligung gem. Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.
            <br />
            <br />
            Die Löschung der Daten erfolgt, wenn die Daten zur Erfüllung vertraglicher oder gesetzlicher Fürsorgepflichten sowie Umgang mit etwaigen
            Gewährleistungs- und vergleichbaren Pflichten nicht mehr erforderlich ist, wobei die Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre
            überprüft wird; im Übrigen gelten die gesetzlichen Aufbewahrungspflichten.
            <br />
          </p>
          <h3>Google Tag Manager</h3>
          <p>
            Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags über eine Oberfläche verwalten können (und so z.B. Google Analytics sowie andere
            Google-Marketing-Dienste in unser Onlineangebot einbinden). Der Tag Manager selbst (welches die Tags implementiert) verarbeitet keine
            personenbezogenen Daten der Nutzer. Im Hinblick auf die Verarbeitung der personenbezogenen Daten der Nutzer wird auf die folgenden Angaben zu den
            Google-Diensten verwiesen. Nutzungsrichtlinien:{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/intl/de/tagmanager/use-policy.html">
              https://www.google.com/intl/de/tagmanager/use-policy.html
            </a>
            .<br />
          </p>
          <h3>Google Analytics</h3>
          <p>
            Wir setzen Google Analytics, einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland („Google“) ein. Google
            verwendet Cookies. Die durch das Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen
            Server von Google in den USA übertragen und dort gespeichert.
            <br />
            <br />
            Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports über
            die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung
            verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt
            werden.
            <br />
            <br />
            Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von
            Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in
            Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
            <br />
            <br />
            Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können die Speicherung
            der Cookies durch eine entsprechende Einstellung ihrer Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das
            Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
            indem sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren:&nbsp;
            <a target="_blank" rel="noopener noreferrer" href="http://tools.google.com/dlpage/gaoptout?hl=de">
              http://tools.google.com/dlpage/gaoptout?hl=de
            </a>
            .<br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die Rechtsgrundlage dieser Verarbeitung Art. 6
            Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
            Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin, dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und hierdurch
            zusichert, das europäische Datenschutzrecht einzuhalten (
            <a target="_blank" rel="noopener noreferrer" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von
            Google (
            <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>
            ) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://adssettings.google.com/authenticated">
              (https://adssettings.google.com/authenticated
            </a>
            ).
            <br />
            <br />
            Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisiert.
          </p>
          <h3>Google Universal Analytics</h3>
          <p>
            Wir setzen Google Analytics in der Ausgestaltung als „
            <a target="_blank" rel="noopener noreferrer" href="https://support.google.com/analytics/answer/2790010?hl=de&amp;ref_topic=6010376">
              Universal-Analytics
            </a>
            “ ein. „Universal Analytics“ bezeichnet ein Verfahren von Google Analytics, bei dem die Nutzeranalyse auf Grundlage einer pseudonymen Nutzer-ID
            erfolgt und damit ein pseudonymes Profil des Nutzers mit Informationen aus der Nutzung verschiedener Geräten erstellt wird (sog.
            „Cross-Device-Tracking“).
          </p>
          <h3>Zielgruppenbildung mit Google Analytics</h3>
          <p>
            Wir setzen Google Analytics ein, um die durch innerhalb von Werbediensten Googles und seiner Partner geschalteten Anzeigen, nur solchen Nutzern
            anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder
            Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Google übermitteln (sog. „Remarketing-“, bzw.
            „Google-Analytics-Audiences“). Mit Hilfe der Remarketing Audiences möchten wir auch sicherstellen, dass unsere Anzeigen dem potentiellen Interesse
            der Nutzer entsprechen.
            <br />
          </p>
          <h3>Google Adsense mit personalisierten Anzeigen</h3>
          <p>
            Wir nutzen die Dienste der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland („Google“).
            <br />
            <br />
            Wir Nutzen den Dienst AdSense, mit dessen Hilfe Anzeigen in unsere Webseite eingeblendet und wir für deren Einblendung oder sonstige Nutzung eine
            Entlohnung erhalten. Zu diesen Zwecken werden Nutzungsdaten, wie z.B. der Klick auf eine Anzeige und die IP-Adresse der Nutzer verarbeitet, wobei
            die IP-Adresse um die letzten beiden Stellen gekürzt wird. Daher erfolgt die Verarbeitung der Daten der Nutzer pseudonymisiert. <br />
            <br />
            Wir setzen Adsense mit personalisierten Anzeigen ein. Dabei zieht Google auf Grundlage der von Nutzern besuchten Websites oder verwendeten Apps und
            den so erstellten Nutzerprofilen Rückschlüsse auf deren Interessen. Werbetreibende nutzen diese Informationen, um ihre Kampagnen an diesen
            Interessen auszurichten, was für Nutzer und Werbetreibende gleichermaßen von Vorteil ist. Für Google sind Anzeigen dann personalisiert, wenn
            erfasste oder bekannte Daten die Anzeigenauswahl bestimmen oder beeinflussen. Dazu zählen unter anderem frühere Suchanfragen, Aktivitäten,
            Websitebesuche, die Verwendung von Apps, demografische und Standortinformationen. Im Einzelnen umfasst dies: demografisches Targeting, Targeting auf
            Interessenkategorien, Remarketing sowie Targeting auf Listen zum Kundenabgleich und Zielgruppenlisten, die in DoubleClick Bid Manager oder Campaign
            Manager hochgeladen wurden.
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die Rechtsgrundlage dieser Verarbeitung Art. 6
            Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
            Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin, dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und hierdurch
            zusichert, das europäische Datenschutzrecht einzuhalten (
            <a target="_blank" rel="noopener noreferrer" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von
            Google (
            <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/technologies/ads">
              https://policies.google.com/technologies/ads
            </a>
            ) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://adssettings.google.com/authenticated">
              (https://adssettings.google.com/authenticated
            </a>
            ).
          </p>
          <h3>Google Adsense mit nicht-personalisierten Anzeigen</h3>
          <p>
            Wir nutzen die Dienste der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland („Google“).
            <br />
            <br />
            Wir Nutzen den Dienst AdSense, mit dessen Hilfe Anzeigen in unsere Webseite eingeblendet und wir für deren Einblendung oder sonstige Nutzung eine
            Entlohnung erhalten. Zu diesen Zwecken werden Nutzungsdaten, wie z.B. der Klick auf eine Anzeige und die IP-Adresse der Nutzer verarbeitet, wobei
            die IP-Adresse um die letzten beiden Stellen gekürzt wird. Daher erfolgt die Verarbeitung der Daten der Nutzer pseudonymisiert. <br />
            <br />
            Wir setzen Adsense mit nicht-personalisierten Anzeigen ein. Dabei werden die Anzeigen nicht auf Grundlage von Nutzerprofilen angezeigt. Nicht
            personalisierte Anzeigen basieren nicht auf früherem Nutzerverhalten. Beim Targeting werden Kontextinformationen herangezogen, unter anderem ein
            grobes (z. B. auf Ortsebene) geografisches Targeting basierend auf dem aktuellen Standort, dem Inhalt auf der aktuellen Website oder der App sowie
            aktuelle Suchbegriffe. Google unterbindet jedwedes personalisierte Targeting, also auch demografisches Targeting und Targeting auf Basis von
            Nutzerlisten.
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die Rechtsgrundlage dieser Verarbeitung Art. 6
            Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
            Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin, dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und hierdurch
            zusichert, das europäische Datenschutzrecht einzuhalten (
            <a target="_blank" rel="noopener noreferrer" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von
            Google (
            <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/technologies/ads">
              https://policies.google.com/technologies/ads
            </a>
            ) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://adssettings.google.com/authenticated">
              (https://adssettings.google.com/authenticated
            </a>
            ).
          </p>
          <h3>Google AdWords und Conversion-Messung</h3>
          <p>
            Wir nutzen das Onlinemarketingverfahren Google "AdWords", um Anzeigen im Google-Werbe-Netzwerk zu platzieren (z.B., in Suchergebnissen, in Videos,
            auf Webseiten, etc.), damit sie Nutzern angezeigt werden, die ein mutmaßliches Interesse an den Anzeigen haben. Dies erlaubt uns Anzeigen für und
            innerhalb unseres Onlineangebotes gezielter anzuzeigen, um Nutzern nur Anzeigen zu präsentieren, die potentiell deren Interessen entsprechen. Falls
            einem Nutzer z.B. Anzeigen für Produkte angezeigt werden, für die er sich auf anderen Onlineangeboten interessiert hat, spricht man hierbei vom
            „Remarketing“. Zu diesen Zwecken wird bei Aufruf unserer und anderer Webseiten, auf denen das Google-Werbe-Netzwerk aktiv ist, unmittelbar durch
            Google ein Code von Google ausgeführt und es werden sog. (Re)marketing-Tags (unsichtbare Grafiken oder Code, auch als "Web Beacons" bezeichnet) in
            die Webseite eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer ein individuelles Cookie, d.h. eine kleine Datei abgespeichert (statt
            Cookies können auch vergleichbare Technologien verwendet werden). In dieser Datei wird vermerkt, welche Webseiten der Nutzer aufgesucht, für welche
            Inhalte er sich interessiert und welche Angebote der Nutzer geklickt hat, ferner technische Informationen zum Browser und Betriebssystem,
            verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung des Onlineangebotes.
            <br />
            <br />
            Ferner erhalten wir ein individuelles „Conversion-Cookie“. Die mit Hilfe des Cookies eingeholten Informationen dienen Google dazu,
            Conversion-Statistiken für uns zu erstellen. Wir erfahren jedoch nur die anonyme Gesamtanzahl der Nutzer, die auf unsere Anzeige geklickt haben und
            zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer
            persönlich identifizieren lassen.
            <br />
            <br />
            Die Daten der Nutzer werden im Rahmen des Google-Werbe-Netzwerks pseudonym verarbeitet. D.h. Google speichert und verarbeitet z.B. nicht den Namen
            oder E-Mailadresse der Nutzer, sondern verarbeitet die relevanten Daten cookie-bezogen innerhalb pseudonymer Nutzerprofile. D.h. aus der Sicht von
            Google werden die Anzeigen nicht für eine konkret identifizierte Person verwaltet und angezeigt, sondern für den Cookie-Inhaber, unabhängig davon
            wer dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat, die Daten ohne diese Pseudonymisierung zu
            verarbeiten. Die über die Nutzer gesammelten Informationen werden an Google übermittelt und auf Googles Servern in den USA gespeichert.
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die Rechtsgrundlage dieser Verarbeitung Art. 6
            Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
            Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin, dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und hierdurch
            zusichert, das europäische Datenschutzrecht einzuhalten (
            <a target="_blank" rel="noopener noreferrer" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von
            Google (
            <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/technologies/ads">
              https://policies.google.com/technologies/ads
            </a>
            ) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://adssettings.google.com/authenticated">
              (https://adssettings.google.com/authenticated
            </a>
            ).
          </p>
          <h3>Google Doubleclick</h3>
          <p>
            Wir nutzen die Dienste der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland („Google“).
            <br />
            <br />
            Wir nutzen das Onlinemarketingverfahren Google "Doubleclick", um Anzeigen im Google-Werbe-Netzwerk zu platzieren (z.B., in Suchergebnissen, in
            Videos, auf Webseiten, etc.). Double Click zeichnet sich dadurch aus, dass Anzeigen in Echtzeit anhand mutmaßlicher Interessen der Nutzer angezeigt
            werden. Dies erlaubt uns Anzeigen für und innerhalb unseres Onlineangebotes gezielter anzuzeigen, um Nutzern nur Anzeigen zu präsentieren, die
            potentiell deren Interessen entsprechen. Falls einem Nutzer z.B. Anzeigen für Produkte angezeigt werden, für die er sich auf anderen Onlineangeboten
            interessiert hat, spricht man hierbei vom „Remarketing“. Zu diesen Zwecken wird bei Aufruf unserer und anderer Webseiten, auf denen das
            Google-Werbe-Netzwerk aktiv ist, unmittelbar durch Google ein Code von Google ausgeführt und es werden sog. (Re)marketing-Tags (unsichtbare Grafiken
            oder Code, auch als "Web Beacons" bezeichnet) in die Webseite eingebunden. Mit deren Hilfe wird auf dem Gerät der Nutzer ein individuelles Cookie,
            d.h. eine kleine Datei abgespeichert (statt Cookies können auch vergleichbare Technologien verwendet werden). In dieser Datei wird vermerkt, welche
            Webseiten der Nutzer aufgesucht, für welche Inhalte er sich interessiert und welche Angebote der Nutzer geklickt hat, ferner technische
            Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung des Onlineangebotes. <br />
            <br />
            Es wird ebenfalls die IP-Adresse der Nutzer erfasst, wobei diese innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt und nur in Ausnahmefällen ganz an einen Server von Google in den USA
            übertragen und dort gekürzt wird. Die vorstehend genannten Informationen können seitens Google auch mit solchen Informationen aus anderen Quellen
            verbunden werden. Wenn der Nutzer anschließend andere Webseiten besucht, können ihm entsprechend seiner mutmaßlichen Interessen auf Grundlage seines
            Nutzerprofiles auf ihn abgestimmten Anzeigen angezeigt werden.
            <br />
            <br />
            Die Daten der Nutzer werden im Rahmen des Google-Werbe-Netzwerks pseudonym verarbeitet. D.h. Google speichert und verarbeitet z.B. nicht den Namen
            oder E-Mailadresse der Nutzer, sondern verarbeitet die relevanten Daten cookie-bezogen innerhalb pseudonymer Nutzerprofile. D.h. aus der Sicht von
            Google werden die Anzeigen nicht für eine konkret identifizierte Person verwaltet und angezeigt, sondern für den Cookie-Inhaber, unabhängig davon
            wer dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat, die Daten ohne diese Pseudonymisierung zu
            verarbeiten. Die von Google-Marketing-Services über die Nutzer gesammelten Informationen werden an Google übermittelt und auf Googles Servern in den
            USA gespeichert.
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die Rechtsgrundlage dieser Verarbeitung Art. 6
            Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
            Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin, dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und hierdurch
            zusichert, das europäische Datenschutzrecht einzuhalten (
            <a target="_blank" rel="noopener noreferrer" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von
            Google (
            <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/technologies/ads">
              https://policies.google.com/technologies/ads
            </a>
            ) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://adssettings.google.com/authenticated">
              (https://adssettings.google.com/authenticated
            </a>
            ).
          </p>
          <h3>Google-Firebase</h3>
          <p>
            Wir nutzen die Entwicklerplattform „Google Firebase“ und die mit ihr verbundenen Funktionen und Dienste, angeboten von Google Ireland Limited,
            Gordon House, Barrow Street, Dublin 4, Irland.
            <br />
            <br />
            Bei Google Firebase handelt es sich um eine Plattform für Entwickler von Applikationen (kurz „Apps“) für mobile Geräte und Webseiten. Google
            Firebase bietet eine Vielzahl von Funktionen, die auf der folgenden Übersichtsseite dargestellt werden:{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://firebase.google.com/products/">
              https://firebase.google.com/products/
            </a>
            .<br />
            <br />
            Die Funktionen umfassen unter anderem die Speicherung von Apps inklusive personenbezogener Daten der Applikationsnutzer, wie z.B. von ihnen
            erstellter Inhalte oder Informationen betreffend ihre Interaktion mit den Apps (sog. „Cloud Computing“). Google Firebase bietet daneben
            Schnittstellen, die eine Interaktion zwischen den Nutzern der App und anderen Diensten erlauben, z.B. die Authentifizierung mittels Diensten wie
            Facebook, Twitter oder mittels einer E-Mail-Passwort-Kombination.
            <br />
            <br />
            Die Auswertung der Interaktionen der Nutzer kann mithilfe des Analyse-Dienstes „Firebase Analytics“ erfolgen. Firebase Analytics ist darauf
            gerichtet zu erfassen, wie Nutzer mit einer App interagieren. Dabei werden Ereignisse (sog „Events“) erfasst, wie z.B. das erstmalige Öffnen der
            App, Deinstallation, Update, Absturz oder Häufigkeit der Nutzung der App. Mit den Events können auch weitere Nutzerinteressen, z.B. für bestimmte
            Funktionen der Applikationen oder bestimmte Themengebiete erfasst werden. Hierdurch können auch Nutzerprofile erstellt werden, die z.B. als
            Grundlage für die Darstellung von auf Nutzer zugeschnittenen Werbehinweisen, verwendet werden können.
            <br />
            <br />
            Google Firebase und die mittels von Google Firebase verarbeiteten personenbezogenen Daten der Nutzer können ferner zusammen mit weiteren Diensten
            von Google, wie z.B. Google Analytics und den Google-Marketing-Diensten und Google Analytics verwendet werden (in diesem Fall werden auch
            gerätebezogene Informationen, wie „Android Advertising ID“ and „Advertising Identifier for iOS“ verarbeitet, um mobile Geräte der Nutzer zu
            identifizieren).
            <br />
            <br />
            Sofern wir die Nutzer um eine Einwilligung bitten (z.B. im Rahmen einer Cookie-Einwilligung), ist die Rechtsgrundlage dieser Verarbeitung Art. 6
            Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der
            Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) verarbeitet.
            <br />
            <br />
            Soweit Daten in den USA verarbeitet werden, weisen wir daraufhin, dass Google unter dem Privacy-Shield-Abkommen zertifiziert ist und hierdurch
            zusichert, das europäische Datenschutzrecht einzuhalten (
            <a target="_blank" rel="noopener noreferrer" href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active">
              https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active
            </a>
            ).
            <br />
            <br />
            Die Datenschutzerklärung von Google ist unter{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">
              https://policies.google.com/privacy
            </a>{" "}
            abrufbar. Weitere Informationen zur Datennutzung zu Marketingzwecken durch Google, erfahren Nutzer auf der Übersichtsseite:{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/technologies/ads?hl=de">
              https://policies.google.com/technologies/ads?hl=de
            </a>
            ,<br />
            <br />
            Wenn Nutzer der interessensbezogenen Werbung durch Google-Marketing-Dienste widersprechen möchten, können Nutzer die von Google gestellten
            Einstellungs- und Opt-Out-Möglichkeiten nutzen:{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://adssettings.google.com/">
              https://adssettings.google.com/
            </a>
          </p>
        </Container>
      </Section>
    </>
  );
}

export default Privacy;
