import React from "react";
import Section from "../partials/Section";
import Container from "../partials/Container";

const contact = {
  forename: "Christina",
  name: "Behringer",
  company: "Christina Behringer",
  street: "An der Sutt 13",
  postalcode: "87435",
  city: "Kempten",
  country: "Germany",
  mail: "christina.behringer@gmx.de",
  phone: "+49 0151 614 33 162",
};

function Terms() {
  const { forename, name, street, postalcode, city, mail, phone } = contact;
  return (
    <>
      <Section />
      <Section>
        <Container>
          <h2>Impressum</h2>
          <h3>
            Christina Behringer
            <br />
            trainiert und coacht.
          </h3>
          <p>
            {forename} {name}
            <br />
            {street}
            <br />
            {postalcode} {city}
          </p>
          <p>
            {mail}
            <br />
            {phone}
          </p>
          <h3>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</h3>
          <p>DE 62045178498</p>
          <h3>Inhaltlich verantwortlich gemäß § 5 TMG:</h3>
          <p>
            {forename} {name}
          </p>
          <h3>Inhalt des Onlineangebotes</h3>
          <p>
            Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen.
            Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der
            dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich
            ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind
            freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
            verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
          </p>
          <h3>Haftung für Links</h3>
          <p>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
            ausschließlich deren Betreiber verantwortlich.
          </p>
          <h3>Urheberrechte</h3>
          <p>
            Der Inhalt dieser Website ist urheberrechtlich geschützt. Die Bereitstellung von Inhalten und Bildmaterial dieser Website auf anderen Websites ist
            nur mit ausdrücklicher Genehmigung der Redaktion gestattet. Die auf dieser Website enthaltenen Angaben werden nach bestem Wissen erstellt und mit
            großer Sorgfalt auf ihre Richtigkeit überprüft. Trotzdem sind inhaltliche und sachliche Fehler nicht vollständig auszuschließen. Es wird keinerlei
            Garantie und Haftung für die Richtigkeit, Aktualität und Vollständigkeit der bereitgestellten Informationen übernommen. Alle Angaben sind ohne
            Gewähr. Dies gilt auch für alle Links zu anderen URLs, die auf dieser Website genannt werden.
          </p>
        </Container>
      </Section>
    </>
  );
}

export default Terms;
