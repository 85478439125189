import React from "react";
import Section from "../partials/Section";
import Container from "../partials/Container";
import ServiceItem from "../partials/ServiceItem";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        {
          src: "/assets/vogelhaus.jpg",
          alt: "",
          h4: "Individuelle Bewerbertrainings",
        },
        {
          src: "/assets/landschaft03.jpg",
          alt: "",
          h4: "Bewerbungstrainings für Firmen",
        },
        {
          src: "https://images.unsplash.com/photo-1474631245212-32dc3c8310c6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
          alt: "",
          h4: "Präsentationstechniken/ Moderationstechniken/ Projektmanagement",
        },
        {
          src: "/assets/kommunikationstraining.jpg",
          alt: "",
          h4: "Kommunikationstrainings",
        },
        {
          src: "https://images.unsplash.com/photo-1515531981002-91b2a11aa75d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80",
          alt: "",
          h4: "Zeitmanagement",
        },
        {
          src: "/assets/landschaft01.jpg",
          alt: "",
          h4: "Einzelcoachings",
        },
        {
          src: "/assets/konfliktmanagement.jpg",
          alt: "",
          h4: "Konfliktmanagement",
        },
        {
          src: "/assets/scrabble_verkaufstrainingt.jpg",
          alt: "",
          h4: "Verkaufstrainings",
        },
        {
          src: "/assets/schriftstuecke1.jpg",
          alt: "",
          h4: "Erstellen persönlicher Schriftstücke",
        },
        {
          src: "/assets/aufraeumcoach.jpg",
          alt: "",
          h4: "Aufräumcoach",
        },
        {
          src: "/assets/scrabble_zufriedenheit.jpg",
          alt: "",
          h4: "Noch nicht das Tüpfelchen auf dem “i” gefunden?",
        },
      ],
    };
  }

  render() {
    return (
      <Section scrollspy="target: [data-uk-scrollspy-class]; delay: 400;">
        <Container>
          <h2 data-uk-scrollspy-class="uk-animation-slide-bottom-medium">
            Meine <span className="uk-text-primary">Angebote</span> für Sie
          </h2>
          <div
            className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small uk-visible@m"
            data-uk-grid=""
            data-uk-height-match="target: > div > div > img; row: false"
            data-uk-scrollspy-class="uk-animation-slide-bottom-medium"
          >
            {this.state.items.map((item, index) => (
              <ServiceItem key={index} src={item.src} alt={item.alt} h4={item.h4} description={item.description} overlay={item.overlay} />
            ))}
          </div>
          <div
            className="uk-position-relative uk-visible-toggle uk-hidden@m"
            tabIndex="-1"
            data-uk-slider="autoplay: 1; velocity: 0.1; autoplay-interval: 2000"
          >
            <ul className="uk-slider-items uk-grid" data-uk-height-match="target: > li > div > div > img; row: false">
              {this.state.items.map((item, index) => (
                <li key={index} className="uk-width-1-2@s uk-width-1-1">
                  <ServiceItem src={item.src} alt={item.alt} h4={item.h4} description={item.description} overlay={item.overlay} />
                </li>
              ))}
            </ul>
          </div>
        </Container>
      </Section>
    );
  }
}

export default Services;
