import React from "react";

class Section extends React.Component {
  render() {
    return (
      <div
        id={this.props.id}
        style={this.props.customStyle}
        data-uk-scrollspy={this.props.scrollspy}
        className={"uk-section uk-section-default " + this.props.cls}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Section;
