import React from "react";
import Container from "../partials/Container";
import Section from "../partials/Section";

class Contact extends React.Component {
  render() {
    return (
      <Section
        id="contact"
        scrollspy="target: [data-uk-scrollspy-class]; delay: 400;"
        cls="uk-background-fixed uk-background-center-center uk-light"
        customStyle={{ backgroundImage: "url(assets/bg1.jpg)" }}
      >
        <Container>
          <h4 className="uk-text-center">Ich freue mich auf Ihre unverbindliche Kontaktaufnahme.</h4>
          <div className="uk-grid-divider uk-child-width-1-1 uk-child-width-1-2@s uk-flex uk-flex-middle" data-uk-grid="">
            <div className="uk-text-right uk-dark">
              Christina Behringer
              <br />
              trainiert und coacht.
            </div>
            <div>
              <div>+49 0151 614 33 162</div>
              <div>christina.behringer@gmx.de</div>
            </div>
          </div>
        </Container>
      </Section>
    );
  }
}

export default Contact;
